import React, { Component } from 'react';
import styled from 'react-emotion';

export const ReviewWrapper = styled('div')`
  width: 100%;
  display: block;
  margin-top: 30px;
  overflow-x: hidden;
  h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
    margin-top: 0;
    text-transform: uppercase;
  }
  h3 {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  &.narrow {
    margin-top: 10px;
  }
`;

class YotpoAlbum extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }
  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.init();
    }
  }

  componentWillUnmount() {
    window.yotpo = undefined;
    window.removeEventListener('load', this.init);
  }

  init = () => {
    var e = document.createElement('script');
    e.type = 'text/javascript';
    e.async = true;
    var apiKey = 'XSUDwJM4iTWs4ycz0OmoljxHroJKK9ea8txNXMUz';
    e.src = 'https://staticw2.yotpo.com/' + apiKey + '/widget.js';
    e.defer = true;
    e.id = 'yotpoScript';
    var t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(e, t);
    if (!this.state.loaded) {
      this.setState({ loaded: true });
    }
  };
  render() {
    return (
      <>
        <ReviewWrapper>
          <h2>#skoterdelen</h2>
          <h3>
            Vill du synas i vår community, tagga dina bilder med #skoterdelen
          </h3>
          <div
            className="yotpo yotpo-pictures-widget"
            data-gallery-id="5d8c534b84f4bc49a65a0163"
          ></div>
        </ReviewWrapper>

        <ReviewWrapper className="narrow">
          <h2>Recensioner</h2>
          <h3>Recensioner från våra kunder</h3>
          <div
            className="yotpo yotpo-reviews-carousel"
            data-header-customisation-enabled="1"
            data-header-customisation-color="#56575f"
            data-header-customisation-font-size="18"
            data-header-customisation-text=""
            data-header-customisation-alignment="left"
            data-background-color="transparent"
            data-mode="most_recent"
            data-type="site"
            data-count="9"
            data-show-bottomline="1"
            data-autoplay-enabled="1"
            data-autoplay-speed="3000"
            data-show-navigation="1"
            data-testimonials-page-enabled="1"
            data-testimonials-page-text="Visa alla recensioner"
            data-testimonials-page-link="https://www.skoterdelen.com/reviews"
          >
            &nbsp;
          </div>
        </ReviewWrapper>
      </>
    );
  }
}

export default YotpoAlbum;
