import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import MaxWidth from '../Layout/MaxWidth';

import GridProduct, {
  StyledProductImage,
  ProductBlockLink,
  Name,
} from '../CategoryPage/GridProduct';
import ProductGrid from './ProductGrid';

import GET_PRODUCTS from './ProductsQuery.gql';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { useChannel } from '../../hooks/useChannel';

const Container = styled('div')``;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding: 0;
  max-width: 70em;
  ${theme('below.md')} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const BlockWrapper = styled('div')`
  width: 100%;
`;
const Row = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  &.second-row {
    padding: 0px 10px 5px 10px;
    margin-bottom: 0;
  }

  ${theme('below.md')} {
    display: block;
    &.second-row {
      padding: 10px 0px 0px 0px;
      margin-bottom: 0;
    }
  }
`;
const Column = styled('div')`
  width: 50%;
  display: inline-block;
  vertical-align: top;

  &.first-column {
  }
  &.second-column {
    > div:last-child {
      margin-bottom: 0;
      > div > div {
        margin-bottom: 0;
      }
    }
  }

  ${theme('below.md')} {
    width: 100%;
  }

  img {
    width: 100%;
  }
  a {
    width: 100%;
  }
`;

const StyledProductGrid = styled(ProductGrid)`
  width: 100%;
  margin: 0;
`;

const StyledGridProduct = styled(GridProduct)`
  width: 50%;
  padding: 0 10px;
  &:first-child {
    /* padding-right: 10px; */
    width: 50%;
  }
  &:last-child {
    /* padding-left: 10px; */
    width: 50%;
  }
  .grid-4 & {
    width: 25% !important;
    padding: 10px !important;
  }

  ${ProductBlockLink} {
    margin: 0;
  }

  ${Name} {
    margin-bottom: 14px;
    @-moz-document url-prefix() {
      margin-bottom: 30px;
    }
    [data-channel='Eyewearstore'] & {
      max-width: 90%;
      display: inline-block;
    }
  }

  ${theme('below.md')} {
    padding-right: 5px;
    padding-left: 5px;
  }
`;

const FirstImage = ({ firstImage }) => {
  const { isWeAhl } = useChannel();
  return (
    <BlockWrapper>
      {firstImage ? (
        <React.Fragment>
          <Column className="first-column">
            {firstImage && (
              <Link to={firstImage.url1}>
                <img
                  src={'https://www.skoterdelen.com' + firstImage.src1}
                  alt="Skoterdelen"
                />
              </Link>
            )}
          </Column>
          <Column className="second-column">
            <Row className="second-row">
              {firstImage && (
                <Link to={firstImage.url2}>
                  <img
                    src={'https://www.skoterdelen.com' + firstImage.src2}
                    alt="Skoterdelen"
                  />
                </Link>
              )}
            </Row>
            <Row>
              <Query
                query={GET_PRODUCTS}
                variables={{
                  first: isWeAhl ? 10 : 2,
                  offset: isWeAhl ? 4 : 1,
                  id: isWeAhl ? 12679 : 11424,
                }}
              >
                {({ loading, error, data }) => {
                  return (
                    <StyledProductGrid
                      products={data?.category?.products?.result ?? []}
                      loading={loading}
                      ProductComponent={StyledGridProduct}
                      push={false}
                    />
                  );
                }}
              </Query>
            </Row>
          </Column>
        </React.Fragment>
      ) : (
        <Query
          query={GET_PRODUCTS}
          variables={{
            first: isWeAhl ? 10 : 4,
            offset: isWeAhl ? 4 : 1,
            id: isWeAhl ? 12679 : 11424,
          }}
        >
          {({ loading, error, data }) => {
            return (
              <StyledProductGrid
                className="grid-4"
                products={data?.category?.products?.result ?? []}
                loading={loading}
                ProductComponent={StyledGridProduct}
                push={false}
              />
            );
          }}
        </Query>
      )}
    </BlockWrapper>
  );
};

const Campaign = ({ firstImage }) => {
  return (
    <MainSectionWrapper>
      <Container>
        <Row>
          <FirstImage firstImage={firstImage} />
        </Row>
      </Container>
    </MainSectionWrapper>
  );
};

export default Campaign;
