import styled from 'react-emotion';
import React from 'react';
import theme from '@jetshop/ui/utils/theme';
import Carousel, { generateDots } from 'my-react-carousel';
import CategoryQuery from './CategoryQuery.gql';
import { useQuery } from '@apollo/react-hooks';
import { Above } from '@jetshop/ui/Breakpoints';
import CategoryLink from '@jetshop/ui/CategoryLink';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useChannel } from '../../hooks/useChannel';

const Wrapper = styled('section')`
  margin-bottom: 0;
  ${theme('above.md')} {
    margin: 2.8rem 10px 2rem 10px;
  }
  ${theme('below.md')} {
    overflow: hidden;
    margin: 2.5rem 8px 2rem 8px;
  }
  .inner {
    position: relative;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .product-card > a {
    margin: 0 0.25rem;
  }

  ${theme('below.sm')} {
    .inner {
      margin-left: -0.1875rem;
      margin-right: -0.1875rem;
    }

    .product-card > a {
      margin: 0 0.1875rem;
    }
  }
`;

const SlideArrow = styled('button')`
  /* width: 2.875rem;
    height: 2.875rem; */
  padding: 0.7rem;
  background-color: transparent;
  box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 24px !important;
    height: 24px !important;
  }
  &.next {
    right: 10px;
  }
  &.prev {
    left: 10px;
  }

  ${theme('below.sm')} {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const ImageWrapper = styled.div`
  img {
    display: block;
    width: 100%;
  }
`;

const Dots = styled.div`
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  span {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
    margin: 2px;
    transition: all 0.2s;
    &.active {
      width: 8px;
      height: 8px;
    }
  }
`;

const findBrand = (brands, brand) => brands.find((b) => brand.id === b.id);

const ProductCarousel = ({
  categories,
  slidesToShow = 1,
  infinite = true,
  brands,
}) => {
  const { url } = useChannel();

  const randomBrand = brands[Math.floor(Math.random() * brands.length)];
  const randomBrandCategory = categories.find(
    (brand) => brand.id === randomBrand.id
  );

  return (
    <div className="inner">
      <Carousel
        infinite={infinite}
        slidesToShow={slidesToShow}
        render={generateDots(
          ({ slides, hasPrevious, hasNext, currentStep, goToStep, dots }) => {
            return (
              <>
                {slides}
                {hasPrevious && (
                  <SlideArrow
                    className="prev"
                    onClick={() => goToStep(currentStep - 1)}
                  >
                    <FontAwesomeIcon icon={['fal', 'angle-left']} />
                  </SlideArrow>
                )}
                {hasNext && (
                  <SlideArrow
                    className="next"
                    onClick={() => goToStep(currentStep + 1)}
                  >
                    <FontAwesomeIcon icon={['fal', 'angle-right']} />
                  </SlideArrow>
                )}
                <Dots>
                  {dots.map(({ index, isActive, onClick }) => (
                    <span
                      key={index}
                      className={isActive && 'active'}
                      onClick={onClick}
                    />
                  ))}
                </Dots>
              </>
            );
          }
        )}
      >
        {randomBrand && randomBrand?.image && (
          <ImageWrapper>
            <CategoryLink category={randomBrandCategory}>
              <img src={`${url}/${randomBrand?.image}`} loading="lazy" />
            </CategoryLink>
          </ImageWrapper>
        )}
        {categories.map((category) => {
          const brand = brands.find((brand) => brand.id === category.id);
          return (
            brand &&
            randomBrandCategory.id !== brand.id && (
              <ImageWrapper>
                <CategoryLink category={category}>
                  <img src={`${url}/${brand?.image}`} loading="lazy" />
                </CategoryLink>
              </ImageWrapper>
            )
          );
        })}
      </Carousel>
    </div>
  );
};

export const BrandSlider = ({
  slidesToShowDesktop = 1,
  slidesToShowMobile = 1,
  brands,
  title = 'OUR BRANDS',
}) => {
  const { error, data, loading } = useQuery(CategoryQuery, {
    variables: { root: 12663 },
    skip: !brands,
  });

  if (loading || error || !data) {
    return null;
  }

  const [root] = data.categories;

  if (!root?.subcategories || !brands) {
    return null;
  }

  return (
    <Wrapper>
      <h2
        style={{
          textAlign: 'center',
          fontSize: '2rem',
          margin: '0 0 .5rem 0',
        }}
      >
        {title}
      </h2>
      <Above breakpoint="md">
        {(matches) =>
          matches ? (
            <ProductCarousel
              slidesToShow={slidesToShowDesktop}
              categories={root.subcategories}
              infinite={true}
              brands={brands}
            />
          ) : (
            <ProductCarousel
              slidesToShow={slidesToShowMobile}
              categories={root.subcategories}
              infinite={true}
              brands={brands}
            />
          )
        }
      </Above>
    </Wrapper>
  );
};
