import { useQuery } from '@apollo/react-hooks';
import theme from '@jetshop/ui/utils/theme';
import React from 'react';
import styled from 'react-emotion';
import HeaderBgEyeWearStore from '../../img/eyewearstore-top-bkg3.jpg';
import HeaderBg from '../../img/header-background.jpg';
import MaxWidth from '../Layout/MaxWidth';
import { AddWishProducts } from '../ui/AddWishProducts';
import GET_PRODUCTS from './CampaignProducts.gql';
import ProductGrid from './ProductGrid';

const ProductGridsWrapper = styled(MaxWidth)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .jetshop-grid {
    width: 60%;
    [data-channel='Eyewearstore'] & {
      width: 100%;
      display: flex;
      justify-content: center;
      > div.product {
        width: 20%;
        margin: 18px;
        border: 5px solid white;
      }
    }
    &.has-push > div {
      width: 33%;
    }
  }

  .addwish-grid {
    &.has-push {
      width: 40%;
      > div {
        padding: 0 9px;
        width: 48%;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  ${theme('below.md')} {
    padding: 0 10px;
    .jetshop-grid {
      [data-channel='Eyewearstore'] & {
        div.product {
          width: 47%;
          margin: 5px;
        }
      }
      width: 100%;
      &.has-push > div {
        width: 50%;
      }
    }
    .addwish-grid {
      &.has-push {
        padding: 5px;
        width: 100%;
        > div {
          width: 50%;
          padding: 5px;
          &:last-child {
            display: block;
          }
        }
      }
    }
  }
`;

const StartpageIntroWrapper = styled('div')`
  text-align: center;
  height: auto;
  padding: 3rem 0 3.5rem 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${({ backgroundPosition }) =>
    backgroundPosition ? backgroundPosition : 'bottom'};
  display: block;

  &[data-channel='Skoterdelen'] {
    background-image: url(${({ backgroundImageUrl }) =>
      backgroundImageUrl ? backgroundImageUrl : HeaderBg});
  }

  &[data-channel='Eyewearstore'] {
    background-image: url(${({ backgroundImageUrl }) =>
      backgroundImageUrl ? backgroundImageUrl : HeaderBgEyeWearStore});
    background-position: ${({ backgroundPosition }) =>
      backgroundPosition ? backgroundPosition : 'top'};
  }

  background-color: ${({ placeholderColor }) =>
    placeholderColor ? placeholderColor : ''};

  ${theme('below.md')} {
    padding: 10px 0 2px 0;
  }
`;

const StartpageIntroTitle = styled('h2')`
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
  background: ${theme('colors.darkGrey')};
  color: #fff;
  padding: 10px 30px;
  transform: rotate(-4deg);
  display: inline-block;
  margin-bottom: 25px;
  ${theme('below.md')} {
    font-size: 1.3rem;
  }
`;

export default (props) => {
  const { StyledGridProduct, addWishCampaignProps, isWeAhl } = props;
  const campaignCategory = useQuery(GET_PRODUCTS, {
    variables: {
      id: isWeAhl ? 12679 : 11423,
      first: isWeAhl ? 4 : 2,
      offset: isWeAhl ? 0 : 1,
    },
  });
  return (
    <StartpageIntroWrapper
      backgroundImageUrl={props.backgroundImageUrl}
      placeholderColor={props.placeholderColor}
      backgroundPosition={props.backgroundPosition}
      data-channel={isWeAhl ? 'Eyewearstore' : 'Skoterdelen'}
    >
      {!isWeAhl && <StartpageIntroTitle>Vi rekommenderar</StartpageIntroTitle>}
      <ProductGridsWrapper>
        {!campaignCategory.error && (
          <ProductGrid
            products={campaignCategory?.data?.category?.products?.result ?? []}
            loading={campaignCategory.loading}
            ProductComponent={StyledGridProduct}
            push={!isWeAhl}
            className="jetshop-grid"
          />
        )}

        {!isWeAhl && <AddWishProducts {...addWishCampaignProps} />}
      </ProductGridsWrapper>
    </StartpageIntroWrapper>
  );
};
