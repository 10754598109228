import { useQuery } from '@apollo/react-hooks';
import { useAddwish } from '@jetshop/flight-addwish';
import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import theme from '@jetshop/ui/utils/theme';
import React, { Fragment } from 'react';
import styled from 'react-emotion';
import { useChannel } from '../../hooks/useChannel';
import GridProduct, { Name } from '../CategoryPage/GridProduct';
import MaxWidth from '../Layout/MaxWidth';
import { AddWishProducts } from '../ui/AddWishProducts';
import { BrandSlider } from './BrandSlider';
import Campaign from './Campaign';
import CampaignHeader from './CampaignHeader';
import CategoryList from './CategoryList';
import ExtraDetails from './ExtraDetails';
import ProductGrid from './ProductGrid';
import GET_STARTPAGE from './StartPageQuery.gql';
import YotpoAlbum from './YotpoAlbum';

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${Name} {
    margin-bottom: 48px;
  }
  /* ${theme('below.md')} { */
    &[data-channel='Eyewearstore'] {
      ${Name} {
        margin-bottom: 28px;
      }
    }
  /* } */
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  max-width: 70em;
`;

const Title = styled('h4')`
  font-size: 22px;
  margin-bottom: ${theme('space.1')};
  ${theme('below.md')} {
    font-size: 18px;
    margin-left: ${theme('space.1')};
  }
`;

const StartPageTitle = ({ text }) => (
  <MainSectionWrapper>
    <Title>{text}</Title>
  </MainSectionWrapper>
);

const startPageComponents = {
  'Campaign Header': CampaignHeader,
  'Category List': CategoryList,
  Title: StartPageTitle,
  Campaign: Campaign,
  'Extra Details': ExtraDetails,
  Brands: BrandSlider,
};

const StyledGridProduct = styled(GridProduct)`
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 20px 0;
`;

const SecondStyledGrid = styled(ProductGrid)`
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: stretch;

  ${theme('below.md')} {
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  > div.product {
    [data-channel='Eyewearstore'] & {
      width: 23%;
      margin: 9px;
      ${theme('above.md')} {
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-left: 0;
        }
      }
      ${theme('below.md')} {
        margin: 0 2px 15px 0;
        width: 48%;
      }
    }
  }
`;

const boxes = {
  k5b05529fe051812b75f51a49: {
    url: 'https://skoterdelen.com',
  },
  '5b05529be051812b75f51a03': {
    url: 'https://skoterdelen.com',
  },
};

const StartPage = ({ startPageId }) => {
  const result = useQuery(GET_STARTPAGE, { variables: { startPageId } });
  const { isWeAhl } = useChannel();

  const { addWishResponseBoxItems } = useAddwish({
    boxes,
    pageLoaded: !result.loading,
  });

  const addWishResponse = addWishResponseBoxItems['k5b05529fe051812b75f51a49'];
  const addWishCampaignResponse =
    addWishResponseBoxItems['5b05529be051812b75f51a03'];

  const addWishProps = {
    ...addWishResponse,
    ProductComponent: StyledGridProduct,
  };

  const addWishCampaignProps = {
    ...addWishCampaignResponse,
    ProductComponent: StyledGridProduct,
    className: 'has-push',
  };
  const items = result?.data?.startPage?.data?.items;
  const backgroundData = items?.find((item) => item.type === 'Bakgrundsbild');
  const backgroundImage = backgroundData?.properties?.find(
    (property) => property.name === 'imageUrl'
  )?.value?.value;
  const backgroundColor = backgroundData?.properties?.find(
    (property) => property.name === 'backgroundColor'
  )?.value?.value;
  const backgroundPosition = backgroundData?.properties?.find(
    (property) => property.name === 'imagePosition'
  )?.value?.value;

  return (
    <Fragment>
      <StartPageWrapper data-channel={isWeAhl ? 'Eyewearstore' : 'Skoterdelen'}>
        <CampaignHeader
          addWishCampaignProps={addWishCampaignProps}
          isWeAhl={isWeAhl}
          backgroundImageUrl={backgroundImage}
          placeholderColor={backgroundColor}
          backgroundPosition={backgroundPosition}
        />

        <ExtraDetails isWeAhl={isWeAhl} />
      </StartPageWrapper>
      <StartPageWrapper>
        <MainSectionWrapper>
          <StartPageRenderer
            result={result}
            startPageComponents={startPageComponents}
            ProductGrid={SecondStyledGrid}
            LoadingPage={null}
          />
          {!isWeAhl && (
            <>
              <AddWishProducts {...addWishProps} />
              <YotpoAlbum />
            </>
          )}
        </MainSectionWrapper>
      </StartPageWrapper>
    </Fragment>
  );
};

export default StartPage;
